import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ButtonBlack from 'components/button/button-black'
import ContactForm from 'components/contact-form'
import { Helmet } from 'react-helmet'
import OGImage from '../../../content/images/logo.png'


interface Props {
  location: Location
}

const MeetTheDoctorPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hero_img = data.hero_img?.childImageSharp?.fluid
  const dr_adam_img = data.dr_adam_img?.childImageSharp?.fluid
  const dr_patterson_img = data.dr_patterson_img?.childImageSharp?.fluid
  const dr_patterson_wife_img = data.dr_patterson_wife_img?.childImageSharp?.fluid
  const dr_patterson_people_img = data.dr_patterson_people_img?.childImageSharp?.fluid
  const dr_charles_img = data.dr_charles_img?.childImageSharp?.fluid
  const green_dots = data.green_dots?.childImageSharp?.fluid
  const dr_adam_family = data.dr_adam_family?.childImageSharp?.fluid


  return (
    <Layout location={location}>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <title>Meet Endodontist Adam B. Broady, DDS, MSD Indianapolis IN</title>
        <meta name="description" content="Meet Indianapolis IN Endodontist Dr. Broady. 317-846-4980" />
        <link rel="canonical" href="https://meridianendoindy.com/meet-us/meet-the-doctor/" />
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Meet Endodontist Adam B. Broady, DDS, MSD Indianapolis IN" />
        <meta name="og:description" content="Meet Indianapolis IN Endodontist Dr. Broady. 317-846-4980" />
        <meta property="og:url" content="https://meridianendoindy.com/meet-us/meet-the-doctor/" />
        <meta property="og:image" content={OGImage} />
        <meta property="og:image:width" content="279" />
        <meta property="og:image:height" content="65" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Meet Endodontist Adam B. Broady, DDS, MSD Indianapolis IN" />
        <meta name="twitter:description" content="Meet Indianapolis IN Endodontist Dr. Broady. 317-846-4980" />
        <meta name="twitter:url" content="https://meridianendoindy.com/meet-us/meet-the-doctor/" />
        <meta name="twitter:image" content={OGImage} />
        <meta property="og:image:width" content="279" />
        <meta property="og:image:height" content="65" />
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="meet-the-doctor-section-bg hero-bg"
        fluid={hero_background}
        alt="Endodontics pc"
      >
      <div id="meet-the-doctor-hero-section">
        <Container>
          <div className="col-lg-6 left-content">
            <h1 className="primary-text hero-heading">
              Meet The Doctors
            </h1>
            <strong>
              <p className="hero-text">
                Adam B. Broady, DDS, MSD<br />
                Sam B. Patterson, DDS, MSD
              </p>
            </strong>
            <p className="text-muted">
              9002 North Meridian St, Suite 201 <br />Indianapolis, IN 46260 <br />
              317-846-4980
            </p>
              <Link to="/contact-us/contact-information-office-map"><button className="hero-section-btn"> Schedule a Consultation</button></Link>
          </div>
          <div className="col-lg-6">
            <Img fluid={hero_img} className="hero-img" alt="the doctor"/>
          </div>
        </Container>
      </div>
    </BackgroundImage>
    <section id="faded-bg-2"></section>
    <section id="meet-the-doctor-treatment-section-1">
      <Container>
        <div className="col-lg-12 text-center">
          <h2 className="primary-text">The Doctors</h2>
          <div className="bottom-border"></div>
        </div>
        <div className="col-lg-12 doctors-container">
          <div className="row mb-3">
            <div className="col-lg-6 contents-left-img">
              <Img fluid={dr_adam_img} className="dr-adam-img"/>
            </div>
            <div className="col-lg-6 contents-right">
              <h4>Dr. Adam B. Broady </h4>
              <h5 className="text-muted">DDS, MSD</h5>
              <p>
                Dr. Broady grew up in Carmel, Indiana.  He attended Indiana University in Bloomington, graduating with High Distinction while earning a B.A. in Biology.  Dr. Broady went on to attend Indiana University’s School of Dentistry and was proudly inducted into the Omicron Kappa Upsilon Honor Society upon graduation.
              </p>
            </div>
          </div>
          <div className="content-container">
            <div className="row adam">
              <div className="col-lg-12">
                <p>
                  Following dental school, Dr. Broady chose to seek further training through a one-year General Practice Residency at the Richard L. Roudebush VA Medical Center in Indianapolis where he gained valuable knowledge and experience in advanced restorative dentistry and in the treatment of medically compromised patients.  Dr. Broady went on to obtain a Masters degree in Endodontics through an additional two-years of practice dedicated solely to the field of Endodontics at Indiana University’s School of Dentistry and the VA Medical Center.  In his specialty program, Dr. Broady was exposed to a wide range of treatment modalities and philosophies allowing him to become well rounded with experience in various aspects of the specialty.
                </p>
              </div>
              <div className="col-lg-7 pt-4">
                <p>
                  Dr. Broady enjoys spending time with his wife, Kara, who he met while attending Cathedral High School here in Indianapolis.  Their two young boys, Parker and Jack, and their daughter Eleanor "Elle" keep them super busy at home.  In his spare time, Dr. Broady enjoys hanging out with friends and family, playing golf, fishing, traveling, and cheering on his favorite sports teams.
                </p>
                <p className="pt-2">Dr. Broady is a member of the American Dental Association, the Indiana Dental Association, the Indianapolis District Dental Society, and the American Association of Endodontists. </p>
              </div>
              <div className="col-lg-5 pt-4">
                <Img fluid={dr_adam_family} className="dr-adam-family-img adam-wife"/>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>

    <section id="meet-the-doctor-treatment-section-1" className="dr-patterson-section">
      <Container>
      <div className="col-lg-12 doctors-container">
          <div className="row mb-3 patterson">
           
            <div className="col-lg-6 contents-left">
              <h4>Dr. Sam B. Patterson</h4>
              <h5 className="text-muted">DDS, MSD</h5>
              <p>
              Dr. Patterson was raised in Zionsville and graduated from Park Tudor High School. He went on to complete his undergraduate education at Miami University and obtained his Bachelor of Science degree in Business Management with a co-major in Premedical Sciences. He went on to complete a post-graduate Master of Science program at Indiana University Purdue University Indianapolis.
              </p>
            </div>
            <div className="col-lg-6">
              <Img fluid={dr_patterson_img} className="dr-adam-img"/>
            </div>
          </div>
          <div className="content-container">
            <div className="row">
              <div className="col-lg-12">
                <p>
                Dr. Patterson received his Doctor of Dental Surgery (D.D.S.) from Indiana University School of Dentistry (IUSD), where he graduated with high academic distinction and was introduced into the Omicron Kappa Upsilon National Dental Honor Society. Following graduation, he chose to complete a one-year General Practice Residency at the Richard L. Roudebush VA Medical Center, where he gained valuable experience in advanced restorative dentistry and in the treatment of medically compromised patients. He then completed his two-year Master of Science in Dentistry (M.S.D.) and Certificate of Specialty through the Indiana University Graduate Endodontic Program at IUSD and the Indianapolis VA Medical Center.
                </p>
              </div>
              <div className="col-lg-5 pt-4">
                <Img fluid={dr_patterson_wife_img} className="dr-adam-family-img patterson-wife"/>
              </div>
              <div className="col-lg-7 pt-4">
                <p>
                During his time in dental school, Dr. Patterson met his wife, Kate, who is a second-grade teacher in Noblesville.  Dr. Patterson enjoys spending time with Kate and their Australian Shepherd, Weller. In his spare time, Dr. Patterson enjoys taking Weller on hikes and to dog parks, meeting up with friends, rooting for the Pacers and Colts, playing golf, traveling, and visiting his family out in Colorado.
                </p>
              </div>
              <div className="col-lg-7 pt-4">
              <Img fluid={dr_patterson_people_img} className="dr-adam-family-img patterson-people-mobile"/>
                <p className="pt-2">Dr. Patterson is a member of the American Dental Association, the Indiana Dental Association, the Indianapolis District Dental Society, and the American Association of Endodontists.</p>
              </div>
              <div className="col-lg-5 pt-4">
                <Img fluid={dr_patterson_people_img} className="dr-adam-family-img patterson-people-desktop"/>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>

    <section id="meet-the-doctor-treatment-section-2" className='dr-steffel-section'>
      <Container>
        <div className="col-lg-12 doctors-container">
          <div className="row mb-4">
            <div className="col-lg-6 contents-left">
              <Img fluid={dr_charles_img} className="dr-charles-img-mobile"/>
              <h4>Dr. Charles L. Steffel</h4>
              <h5 className="text-muted">DDS, MSD</h5>
              <p>Dr. Steffel officially retired from practice in May of 2022.</p>
            </div>
            <div className="col-lg-6 contents-right-img">
              <Img fluid={dr_charles_img} className="dr-charles-img"/>
            </div>
          </div>
        </div>
      </Container>
    </section>

    <section id="meet-the-doctor-treatment-section-3">
      <ContactForm />
    </section>

    </Layout>
  )
}

export default MeetTheDoctorPage

export const query = graphql`
  query MeetTheDoctorPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
      }
    },
    hero_background: file(name: { eq: "introduction-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_img: file(name: { eq: "meet-us-img" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    dr_adam_img: file(name: { eq: "dr-adam" }) {
      childImageSharp {
        fluid(maxWidth: 417, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    dr_patterson_img: file(name: { eq: "dr-patterson" }) {
      childImageSharp {
        fluid(maxWidth: 417, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    dr_patterson_wife_img: file(name: { eq: "dr-patterson-with-wife" }) {
      childImageSharp {
        fluid(maxWidth: 417, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    dr_patterson_people_img: file(name: { eq: "dr-patterson-with-people" }) {
      childImageSharp {
        fluid(maxWidth: 417, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    dr_charles_img: file(name: { eq: "dr-charles" }) {
      childImageSharp {
        fluid(maxWidth: 417, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    green_dots: file(name: { eq: "doctors-green-dots" }) {
      childImageSharp {
        fluid(maxWidth: 72, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    dr_adam_family: file(name: { eq: "dr-adam-family" }) {
      childImageSharp {
        fluid(maxWidth: 417, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
